// ** Redux Imports
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"

// ** Axios Imports
import axios from "axios"

// ** Constants
import { API_SECTIONS_URL } from "../constants"

export const getListSections = createAsyncThunk(
    "sections/getListSections",
    async ({
        page = 1,
        limit = 20,
        search,
        branch,
        noPaginate
    } = {}) => {

        let queryParams = `page=${page}&limit=${limit}`
        if (search) {
            queryParams += `&search=${search}`
        }
        if (branch) {
            queryParams += `&branch=${branch}`
        }
        if (noPaginate) {
            queryParams += `&noPaginate=true`
        }
        const response = await axios.get(`${API_SECTIONS_URL}?${queryParams}`)
        return response.data
    }
)

export const storeSection = createAsyncThunk(
    "sections/storeSection",
    async (data, { dispatch, getState }) => {
        const response = await axios.post(
            `${API_SECTIONS_URL}`,
            data
        )
        return response.data
    }
)

export const updateSection = createAsyncThunk(
    "sections/updateSection",
    async (data, { dispatch, getState }) => {
        const response = await axios.put(
            `${API_SECTIONS_URL}/${data._id}`,
            data
        )
        return response.data
    }
)

export const sectionsSlice = createSlice({
    name: "sections",
    initialState: {
        list: [],
        totalPages: 1,
        loading: true,
        hasNextPage: false,
        pagingCounter: 0,
        totalDocs: 0
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getListSections.pending, (state, action) => {
                state.loading = true
            })
            .addCase(getListSections.fulfilled, (state, action) => {
                state.list = action.payload.docs || action.payload
                state.totalPages = action.payload.totalPages
                state.hasNextPage = action.payload.hasNextPage
                state.pagingCounter = action.payload.pagingCounter
                state.totalDocs = action.payload.totalDocs
                state.loading = false
            })
    }
})

export default sectionsSlice.reducer
