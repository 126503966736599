// ** Redux Imports
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"

// ** Axios Imports
import axios from "axios"

// ** Constants
import { API_USERS_URL } from "../constants"

export const getListUsers = createAsyncThunk(
    "users/getListUsers",
    async ({
        page = 1,
        limit = 100,
        search,
        noPaginate,
        status,
        roles,
        type
    } = {}) => {

        let queryParams = `page=${page}&limit=${limit}`
        if (search) {
            queryParams += `&search=${search}`
        }
        if (noPaginate) {
            queryParams += `&noPaginate=true`
        }
        if (status) {
            queryParams += `&status=${status}`
        }
        if (roles) {
            queryParams += `&roles=${roles}`
        }
        if (type) {
            queryParams += `&type=${type}`
        }
        const response = await axios.get(`${API_USERS_URL}?${queryParams}`)
        return response.data
    }
)

export const getListUsersAsClients = createAsyncThunk(
    "users/getListUsersAsClients",
    async ({
        page = 1,
        limit = 20,
        search,
        noPaginate,
        status,
        roles
    } = {}) => {

        let queryParams = `page=${page}&limit=${limit}`
        if (search) {
            queryParams += `&search=${search}`
        }
        if (noPaginate) {
            queryParams += `&noPaginate=true`
        }
        if (status) {
            queryParams += `&status=${status}`
        }
        if (roles) {
            queryParams += `&roles=${roles}`
        }
        const response = await axios.get(`${API_USERS_URL}?${queryParams}`)
        return response.data
    }
)

export const getUserById = createAsyncThunk(
    "users/getUserById",
    async (id, { dispatch, getState }) => {
        const response = await axios.get(
            `${API_USERS_URL}/${id}`
        )
        return response.data
    }
)

export const checkUserExists = createAsyncThunk(
    "users/checkUserExists",
    async (data, { dispatch, getState }) => {
        const response = await axios.post(
            `${API_USERS_URL}/check/exists`,
            data
        )
        return response.data
    }
)

export const storeUser = createAsyncThunk(
    "users/storeUser",
    async (data, { dispatch, getState }) => {
        const response = await axios.post(
            `${API_USERS_URL}`,
            data
        )
        return response.data
    }
)

export const updateUser = createAsyncThunk(
    "users/updateUser",
    async (data, { dispatch, getState }) => {
        const response = await axios.put(
            `${API_USERS_URL}/${data._id}`,
            data
        )
        return response.data
    }
)

export const attachFiles = createAsyncThunk(
    "users/attachFiles",
    async (data, { dispatch, getState }) => {

        const formData = new FormData()
        formData.append('files', data.file[0])
        const axiosConfig = {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }

        const response = await axios.post(
            `${API_USERS_URL}/upload/files/${data.dataToSend.userId}`,
            formData,
            axiosConfig
        )
        return response.data
    }
)

export const removeFile = createAsyncThunk(
    "users/removeFile",
    async (data, { dispatch, getState }) => {
        const response = await axios.delete(
            `${API_USERS_URL}/files/remove/${data.userId}/${data.index}`
        )
        return response.data
    }
)

export const removeUser = createAsyncThunk(
    "users/removeUser",
    async (data, { dispatch, getState }) => {
        const response = await axios.delete(
            `${API_USERS_URL}/${data.userId}`
        )
        return response.data
    }
)

export const getUserByInternalId = createAsyncThunk(
    "users/getUserByInternalId",
    async (internalId, { dispatch, getState }) => {
        const response = await axios.get(
            `${API_USERS_URL}/internalId/${internalId}`
        )
        return response.data
    }
)

export const usersSlice = createSlice({
    name: "users",
    initialState: {
        list: [],
        totalPages: 1,
        loading: true,
        hasNextPage: false,
        pagingCounter: 0,
        totalDocs: 0,
        branchName: null
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getListUsers.pending, (state, action) => {
                state.loading = true
            })
            .addCase(getListUsers.fulfilled, (state, action) => {
                state.list = action.payload.docs || action.payload
                state.totalPages = action.payload.totalPages
                state.hasNextPage = action.payload.hasNextPage
                state.pagingCounter = action.payload.pagingCounter
                state.totalDocs = action.payload.totalDocs
                state.loading = false
            })
    }
})

export default usersSlice.reducer
