// ** Redux Imports
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"

// ** Axios Imports
import axios from "axios"

// ** Constants
import { API_COMPANIES_URL } from "../constants"

export const getListCompanies = createAsyncThunk(
    "companies/getListCompanies",
    async ({
        page = 1,
        limit = 20,
        search,
        noPaginate,
        branch,
        status
    } = {}) => {

        let queryParams = `page=${page}&limit=${limit}`
        if (search) {
            queryParams += `&search=${search}`
        }
        if (noPaginate) {
            queryParams += `&noPaginate=true`
        }
        if (branch) {
            queryParams += `&branch=${branch}`
        }
        if (status) {
            queryParams += `&status=${status}`
        }
        const response = await axios.get(`${API_COMPANIES_URL}?${queryParams}`)
        return response.data
    }
)

export const checkBusinessLine = createAsyncThunk(
    "companies/checkBusinessLine",
    async (data, { dispatch, getState }) => {
        const response = await axios.get(
            `${API_COMPANIES_URL}/check/businessLine/${data.businessLineId}/${data.branchId}`
        )
        return response.data
    }
)

export const storeCompany = createAsyncThunk(
    "companies/storeCompany",
    async (data, { dispatch, getState }) => {
        const response = await axios.post(
            `${API_COMPANIES_URL}`,
            data
        )
        return response.data
    }
)

export const updateCompany = createAsyncThunk(
    "companies/updateCompany",
    async (data, { dispatch, getState }) => {
        const response = await axios.put(
            `${API_COMPANIES_URL}/${data._id}`,
            data
        )
        return response.data
    }
)

export const getCompanyById = createAsyncThunk(
    "companies/getCompanyById",
    async (id, { dispatch, getState }) => {
        const response = await axios.get(
            `${API_COMPANIES_URL}/byId/${id}`
        )
        return response.data
    }
)


export const companiesSlice = createSlice({
    name: "companies",
    initialState: {
        list: [],
        totalPages: 1,
        loading: true,
        hasNextPage: false,
        pagingCounter: 0,
        totalDocs: 0
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getListCompanies.pending, (state, action) => {
                state.loading = true
            })
            .addCase(getListCompanies.fulfilled, (state, action) => {
                state.list = action.payload.docs || action.payload
                state.totalPages = action.payload.totalPages
                state.hasNextPage = action.payload.hasNextPage
                state.pagingCounter = action.payload.pagingCounter
                state.totalDocs = action.payload.totalDocs
                state.loading = false
            })
    }
})

export default companiesSlice.reducer
