// ** Redux Imports
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"

// ** Axios Imports
import axios from "axios"

// ** Constants
import { API_COMPANY_ORDERS_URL } from "../constants"

export const getListCompanyOrders = createAsyncThunk(
    "companyOrders/getListCompanyOrders",
    async ({
        page = 1,
        limit = 20,
        search,
        noPaginate,
        order
    } = {}) => {

        let queryParams = `page=${page}&limit=${limit}`
        if (search) {
            queryParams += `&search=${search}`
        }
        if (noPaginate) {
            queryParams += `&noPaginate=true`
        }
        if (order) {
            queryParams += `&order=${order}`
        }
        const response = await axios.get(`${API_COMPANY_ORDERS_URL}?${queryParams}`)
        return response.data
    }
)

export const getCompanyOrderById = createAsyncThunk(
    "companyOrders/getCompanyOrderById",
    async (id, { dispatch, getState }) => {
        const response = await axios.get(
            `${API_COMPANY_ORDERS_URL}/${id}`
        )
        return response.data
    }
)

export const storeCompanyOrder = createAsyncThunk(
    "companyOrders/storeCompanyOrder",
    async (data, { dispatch, getState }) => {
        const response = await axios.post(
            `${API_COMPANY_ORDERS_URL}`,
            data
        )
        return response.data
    }
)

export const updateCompanyOrder = createAsyncThunk(
    "companyOrders/updateCompanyOrder",
    async (data, { dispatch, getState }) => {
        const response = await axios.put(
            `${API_COMPANY_ORDERS_URL}/${data._id}`,
            data
        )
        return response.data
    }
)

export const companyOrdersSlice = createSlice({
    name: "companyOrders",
    initialState: {
        list: [],
        totalPages: 1,
        loading: true,
        hasNextPage: false,
        pagingCounter: 0,
        totalDocs: 0
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getListCompanyOrders.pending, (state, action) => {
                state.loading = true
            })
            .addCase(getListCompanyOrders.fulfilled, (state, action) => {
                state.list = action.payload.docs || action.payload
                state.totalPages = action.payload.totalPages
                state.hasNextPage = action.payload.hasNextPage
                state.pagingCounter = action.payload.pagingCounter
                state.totalDocs = action.payload.totalDocs
                state.loading = false
            })
    }
})

export default companyOrdersSlice.reducer
