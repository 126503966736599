// ** Reducers Imports
import layout from "./layout"
import auth from "./authentication"
import branches from "./branches"
import countries from "./countries"
import states from "./states"
import cities from "./cities"
import sections from "./sections"
import locations from "./locations"
import businessLines from "./businessLines"
import users from "./users"
import companies from "./companies"
import roles from "./roles"
import clients from "./clients"
import orders from "./orders"
import companyOrders from "./companyOrders"

const rootReducer = {
  auth,
  layout,
  branches,
  countries,
  states,
  cities,
  sections,
  locations,
  businessLines,
  users,
  companies,
  roles,
  clients,
  orders,
  companyOrders
}

export default rootReducer
